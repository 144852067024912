const guide = {
    start_building: 'Comenzar a construir',
    get_started: 'Empezar',
    categories: {
        featured: 'Popular y para ti',
        Traditional: 'Aplicación web tradicional',
        SPA: 'Aplicación de una sola página',
        Native: 'Nativa',
        MachineToMachine: 'Máquina a máquina',
        Protected: 'Integración no oficial',
        ThirdParty: 'Aplicación de terceros',
        SAML: 'Aplicación SAML',
    },
    filter: {
        title: 'Filtrar framework',
        placeholder: 'Buscar un framework',
    },
    checkout_tutorial: 'Ver el tutorial de {{name}}',
    do_not_need_tutorial: 'Si no necesitas un tutorial, puedes continuar sin una guía de framework',
    finish_and_done: 'Terminar y finalizar',
    cannot_find_guide: '¿No puedes encontrar tu guía?',
    describe_guide_looking_for: 'Describe la guía que estás buscando',
    request_guide_successfully: 'Tu solicitud ha sido enviada correctamente. ¡Gracias!',
    app: {
        select_framework_or_tutorial: 'Seleccionar un framework o tutorial',
        guide_modal_title: 'Comenzar con el SDK y las guías',
        modal_subtitle: 'Agiliza el proceso de desarrollo de tu aplicación con nuestro SDK preconstruido y tutoriales.',
        select_a_framework: 'Seleccionar un framework',
        continue_without_framework: 'Crear una aplicación sin framework',
        describe_guide_looking_for_placeholder: 'Ej.: Quiero integrar Logto en mi aplicación de Angular.',
    },
    api: {
        modal_title: 'Comenzar con tutoriales',
        modal_subtitle: 'Agiliza el proceso de desarrollo de tu aplicación con nuestros tutoriales preconstruidos.',
        select_a_tutorial: 'Seleccionar un tutorial',
        continue_without_tutorial: 'Continuar sin tutorial',
        describe_guide_looking_for_placeholder: 'Ej.: Quiero proteger mi API utilizando deno.',
    },
};
export default Object.freeze(guide);
