const user_scopes = {
    descriptions: {
        custom_data: 'Tus datos personalizados',
        email: 'Tu dirección de correo electrónico',
        phone: 'Tu número de teléfono',
        profile: 'Tu nombre, nombre de usuario, avatar y otra información de perfil',
        roles: 'Tus roles',
        identities: 'Tus identidades sociales vinculadas',
        'urn:logto:scope:organizations': 'La información sobre tus organizaciones',
        'urn:logto:scope:organization_roles': 'Tus roles en la organización',
        address: 'Tu dirección',
    },
};
export default Object.freeze(user_scopes);
