const users = {
    page_title: 'Gestión de usuarios',
    title: 'Gestión de usuarios',
    subtitle: 'Administrar identidades de usuario, incluyendo la creación de usuarios, la edición de información de usuario, la visualización de registros de usuario, la restablecimiento de contraseña y la eliminación de usuarios.',
    create: 'Agregar usuario',
    create_subtitle: 'Proporcione al menos uno de los siguientes campos para continuar.',
    error_missing_identifier: 'Debe proporcionar al menos un identificador para crear un usuario.',
    user_name: 'Usuario',
    application_name: 'De la aplicación',
    latest_sign_in: 'Último inicio de sesión',
    create_form_username: 'Nombre de usuario',
    create_form_password: 'Contraseña',
    create_form_name: 'Nombre completo',
    placeholder_name: 'Fulano de Tal',
    placeholder_email: 'fulano@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+34 123 456 789',
    unnamed: 'Sin nombre',
    search: 'Buscar por nombre, correo electrónico, teléfono o nombre de usuario',
    check_user_detail: 'Ver detalles del usuario',
    placeholder_title: 'Gestión de usuarios',
    placeholder_description: 'Cada usuario tiene un perfil que contiene toda su información. Consta de datos básicos, identidades sociales y datos personalizados.',
};
export default Object.freeze(users);
